import Vue from 'vue'
import VueRouter from 'vue-router'
import PublicLayout from '@/components/layouts/Public.vue'
import EmptyRouterView from '@/components/layouts/EmptyRouterView.vue'
import EmptyEquRouterView from '@/components/layouts/EmptyEquRouterView.vue'
// import ReturnRouterView from '@/components/layouts/ReturnRouterView.vue'
import TicketRouterView from '@/components/layouts/TicketRouterView.vue'
import KitRouterView from '@/components/layouts/KitRouterView.vue'
import ServiceCalibrationRouterView from '@/components/layouts/ServiceCalibrationRouterView.vue'
import EquipmentManagementRouterView from '@/components/layouts/EquipmentManagementRouterView.vue'
import PurchaseRouterView from '@/components/layouts/PurchaseRouterView.vue'
import CatalogLayout from '@/components/layouts/CatalogLayout.vue'
import AppLayout from '@/components/layouts/App.vue'
import PageNotFound from '@/components/layouts/PageNotFound.vue'
import Login from '@/authorization/Login.vue'
import PlatformLogin from '@/authorization/PlatformLogin.vue'
import ProcoreWarning from '@/authorization/ProcoreWarning.vue'
import { isAuthenticated, getMatchedRoute, hasEntitledPermission, somePermitted, getLoginPath } from '@/helpers/utils.js'
import { setProcoreIntegration, checkProcoreIntegration } from '@/helpers/procore.js'
import { EventBus } from '@/helpers/eventBus'
import store from '../store/index'

const ReqHome = () => import('@/pages/requisitions/ReqHome.vue')
const ReturnHome = () => import('@/pages/returns/ReturnHome.vue')
const ReturnHistory = () => import('@/pages/returns/ReturnHistory.vue')
const ReturnSearch = () => import('@/pages/returns/ReturnSearch.vue')
const ReturnTicket = () => import('@/pages/returns/ReturnTicket.vue')
const RequisitionsSearch = () => import('@/pages/requisitions/RequisitionsSearch.vue')
const ReservationHome = () => import('@/pages/reservations/ReservationHome.vue')
const ReservationSearch = () => import('@/pages/reservations/ReservationSearch.vue')
const ReservationCart = () => import('@/pages/reservations/ReservationCart.vue')
const ReservationCheckout = () => import('@/pages/reservations/ReservationCheckout.vue')
const Cart = () => import('@/pages/requisitions/ReqCart.vue')
const SchedulingCart = () => import('@/scheduling/schedulingRequest/SchedulingCart.vue')
const Checkout = () => import('@/pages/requisitions/ReqCheckout.vue')
const SchedulingCheckout = () => import('@/scheduling/schedulingRequest/SchedulingCheckout.vue')
const Requests = () => import('@/pages/requisitions/ReqRequests.vue')
const ReservationHistory = () => import('@/pages/reservations/ReservationHistory.vue')
const ProductDetail = () => import('@/pages/requisitions/ReqProductDetail.vue')
const ReservationProductDetail = () => import('@/pages/reservations/ReservationProductDetail.vue')
const BleSensorsPage = () => import('@/administration/BleSensorsPage.vue')
const ChangeMyPassword = () => import('@/administration/ChangeMyPassword.vue')
const UserPreferencesPage = () => import('@/administration/UserPreferencesPage.vue')
const DataLoader = () => import('@/administration/DataLoader.vue')
const OrgsettingsPage = () => import('@/administration/OrgsettingsPage.vue')
const ListsNumberingPage = () => import('@/administration/ListsNumberingPage.vue')
const ListsDescriptionsPage = () => import('@/administration/ListsDescriptionsPage.vue')
const ListsCategoryPage = () => import('@/administration/ListsCategoryPage.vue')
const ListsCertificationPage = () => import('@/administration/ListsCertificationPage.vue')
const ListsClassesPage = () => import('@/administration/ListsClassesPage.vue')
const ListsDepartmentsPage = () => import('@/administration/ListsDepartmentsPage.vue')
const ListsManufacturersPage = () => import('@/administration/ListsManufacturersPage.vue')
const ListsShippingMethodsPage = () => import('@/administration/ListsShippingMethodsPage.vue')
const ListsLocationsPage = () => import('@/administration/ListsLocationsPage.vue')
const ListsCostCentersPage = () => import('@/administration/ListsCostCentersPage.vue')
const ListsCostCodesPage = () => import('@/administration/ListsCostCodesPage.vue')
const ListsStatusCodesPage = () => import('@/administration/ListsStatusCodesPage.vue')
const ListsVendorsPage = () => import('@/administration/ListsVendorsPage.vue')
const ListsTaskCategoriesPage = () => import('@/administration/TaskCategories/ListsTaskCategoriesPage.vue')
const ListsTaskPrioritiesPage = () => import('@/administration/TaskPriorities/ListsTaskPrioritiesPage.vue')
const ListsTaskStatusesPage = () => import('@/administration/TaskStatuses/ListsTaskStatusesPage.vue')
const ListsContractorsPage = () => import('@/administration/ListsContractorsPage.vue')
const ListsCustomersPage = () => import('@/administration/ListsCustomersPage.vue')
const ListsEmployeesPage = () => import('@/administration/ListsEmployeesPage.vue')
const ListsEmployeeTypesPage = () => import('@/administration/ListsEmployeeTypesPage.vue')
const ListsUnitsOfMeasurePage = () => import('@/administration/ListsUnitsOfMeasurePage.vue')
const ListsServiceClassesPage = () => import('@/administration/ListsServiceClassesPage.vue')
const BleTagsPage = () => import('@/administration/BleTagsPage.vue')
const BleCombinedPage = () => import('@/administration/BleCombinedPage.vue')
const CatalogsView = () => import('@/catalogs/CatalogsView.vue')
const CatalogHome = () => import('@/catalogs/CatalogHome.vue')
const CatalogDetail = () => import('@/catalogs/CatalogDetail.vue')
const ItemBrowser = () => import('@/items/ItemBrowser.vue')
const LowLevelBrowser = () => import('@/pages/materials/LowLevelBrowser.vue')
const DashboardPage = () => import('@/dashboard/DashboardPage.vue')
const CannedReports = () => import('@/pages/reports/CannedReports.vue')
const CustomReports = () => import('@/pages/reports/CustomReports.vue')
const ScheduledReports = () => import('@/pages/reports/ScheduledReports.vue')
const EditReports = () => import('@/pages/reports/EditReports.vue')
const TransferTicket = () => import('@/pages/tickets/TransferTicket.vue')
const TransferTicketCreate = () => import('@/pages/tickets/TransferTicketCreate.vue')
const TransferHolder = () => import('@/pages/tickets/TransferHolder.vue')
const Templates = () => import('@/pages/tickets/Templates.vue')
const TemplateCreate = () => import('@/pages/tickets/TemplateCreate.vue')
const TemplateHolder = () => import('@/pages/tickets/TemplateHolder.vue')
const PickTicketCreate = () => import('@/pages/tickets/PickTicketCreate.vue')
const CountInventory = () => import('@/pages/tickets/CountInventory.vue')
const CreateCountTickets = () => import('@/pages/tickets/CreateCountTickets.vue')
const CompanyCalendarPage = () => import('@/administration/CompanyCalendarPage.vue')
const ListsDataMergeUtility = () => import('@/administration/ListsDataMergeUtility.vue')
const RateSheets = () => import('@/billing/RateSheets.vue')
const RateSheetsView = () => import('@/billing/RateSheetsView.vue')
const EquipmentUsageLog = () => import('@/billing/EquipmentUsageLog')
const EquipmentUsageAddItem = () => import('@/billing/EquipmentUsageAddItem')
const BillingBrowser = () => import('@/billing/BillingBrowser.vue')
const InvoiceBrowser = () => import('@/billing/InvoiceBrowser.vue')
const InvoiceBrowserGenerate = () => import('@/billing/InvoiceBrowserGenerate.vue')
const ImportMeter = () => import('@/billing/ImportMeter')
const ExportTemplates = () => import('@/billing/ExportTemplates.vue')
const PickTicket = () => import('@/pages/tickets/PickTicket.vue')
const PickHolder = () => import('@/picktickets/PickHolder.vue')
const ConfigureUserRolesSwitch = () => import('@/platform/RolesAndPermissions/ConfigureUserRolesSwitch.vue')
const UsersAssignedToRole = () => import('@/platform/RolesAndPermissions/UsersAssignedToRole.vue')
const DocumentCentral = () => import('@/documentcentral/DocumentCentral.vue')
const DocumentCentralRecycleBin = () => import('@/documentcentral/RecycleBin.vue')
const SafetyPlanHome = () => import('@/safetyplan/SafetyPlanHome/SafetyPlanHome.vue')
const ExportData = () => import('@/billing/ExportData.vue')
const IntegrationCatalogPage = () => import('@/administration/integration/IntegrationCatalogPage.vue')
const IntegrationSettingsPage = () => import('@/administration//integration/IntegrationSettingsPage.vue')
const PurchaseOrders = () => import('@/purchase/PurchaseOrders.vue')
const PurchaseOrderDetail = () => import('@/purchase/PODetail.vue')
const POAddItem = () => import('@/purchase/POAddItem.vue')
const ServiceRequestBrowser = () => import('@/pages/service/requests/ServiceRequestBrowser.vue')
const EditServiceRequest = () => import('@/pages/service/requests/EditServiceRequest.vue')
const AddItemsToServiceRequest = () => import('@/pages/service/requests/AddItemsToServiceRequest.vue')
const BrowseServiceSchedule = () => import('@/pages/service/schedule/BrowseServiceSchedule.vue')
const WorkOrders = () => import('@/pages/service/workorders/WorkOrders.vue')
const WorkOrdersEdit = () => import('@/pages/service/workorders/WorkOrdersEdit.vue')
const InventoryAdjustmentsBrowser = () => import('@/pages/tickets/InventoryAdjustmentsBrowser.vue')
const CountTicketToolRecord = () => import('@/pages/tickets/CountTicketToolRecord.vue')
const ReceivingTickets = () => import('@/purchase/ReceivingTickets.vue')
const ReceivingTicketDetail = () => import('@/purchase/ReceivingTicketDetail.vue')
const RecycleBin = () => import('@/recyclebin/RecycleBin.vue')
const CustomFields = () => import('@/administration/CustomFields.vue')
const RetiredTools = () => import('@/pages/tools/RetiredTools.vue')
const KitDetail = () => import('@/pages/tools/KitDetail.vue')
const DotGoogelMap = () => import('@/geomap/DotMap.vue')
const ListsVisibilityPage = () => import('@/administration/ListsVisibilityPage.vue')
const CustomDashboards = () => import('@/customdashboard/CustomDashboards.vue')
const TasksHome = () => import('@/tasks/TasksHome.vue')
const EditDashboard = () => import('@/customdashboard/NewDashboard.vue')
const LocationMap = () => import('@/geomap/LocationMap.vue')
const UsersPage = () => import('@/administration/UsersPage.vue')
const FuelTransactionBrowser = () => import('@/fleet/FuelTransactionBrowser.vue')
const FuelCards = () => import('@/fleet/ListsFuelCards.vue')
const FuelEmployees = () => import('@/fleet/ListsFuelEmployees.vue')
const FuelCardsItem = () => import('@/administration/FuelCardsCreate.vue')
const FuelImportEmployees = () => import('@/fleet/ListImportFuelEmployees.vue')
const FuelCardImport = () => import('@/fleet/FuelCardImport.vue')
const ImportFuelTransaction = () => import('@/fleet/ImportFuelTransaction.vue')
const MoveToCloud = () => import('@/administration/MoveToCloud.vue')
const InsightsPage = () => import('@/insights/InsightsPage.vue')
const RequisitionRequestBrowser = () => import('@/pages/requisitions/RequisitionRequestBrowser.vue')
const RequisitionBrowserView = () => import('@/pages/requisitions/RequisitionBrowserView.vue')
const RequisitionRequestBrowserCreate = () => import('@/pages/requisitions/RequisitionRequestAddTemplate.vue')
const JobStock = () => import('@/JobStock/JobStock.vue')
const redirectToHelpSite = () => {
  window.open('https://help.aligntech.co', '_blank')
  router.push('/')
}

const redirectToEHSAdmin = () => {
  let url = ''
  if (process.env.VUE_APP_SAFETY_URL === null || process.env.VUE_APP_SAFETY_URL === undefined) {
    url = 'https://ehs.toolwatch.com'
  } else {
    url = process.env.VUE_APP_SAFETY_URL
  }
  window.open(`${url}/admin/adminMPV2`, '_blank')
  router.push('/')
}
const ApiCoreLogin = () => import('@/pages/ApiCoreLogin.vue')
const SafetyPage = () => import('@/safety/SafetyPage.vue')
const SchedulingBrowser = () => import('@/scheduling/SchedulingBrowser/SchedulingBrowser.vue')
const SchedulingApproveReject = () => import('@/scheduling/SchedulingBrowser/SchedulingApproveReject.vue')
const SchedulingRequestNew = () => import('@/scheduling/schedulingRequest/SchedulingRequest.vue')
const SchedulingCalendar = () => import('@/scheduling/SchedulingCalendar/SchedulingCalendar.vue')
const WorkflowBrowser = () => import('@/workmanagement/WorkflowBrowser.vue')
const WorkflowBuilder = () => import('@/workmanagement/WorkflowBuilder.vue')
const WorkflowNew = () => import('@/workmanagement/CreateNewWorkflow.vue')
const WorkflowApprovals = () => import('@/workmanagement/WorkflowApprovals.vue')
const workForcePage = () => import('@/workforce/WorkForcePage.vue')

Vue.use(VueRouter)

const isJobsitePortal = () => {
  return store.getters.jobSiteUser === 0 || store.getters.showPlatformNavigation
}

const isWarehouse = () => {
  return store.getters.jobSiteUser === 1 || store.getters.showPlatformNavigation
}

const isBackoffice = () => {
  return store.getters.jobSiteUser === 2 || store.getters.showPlatformNavigation
}

const isEHSOnly = () => {
  return store.getters.userEntitlement.includes('SafetyTierMaxAdmins') &&
  !store.getters.userEntitlement.includes('T_And_E')
}

const isFirstLogin = () => {
  return store.getters.isFLogin
}

const isShowScheduling = () => {
  return ((isJobsitePortal() && (store.getters.showCloudReservations)) ||
  (isBackoffice() && store.getters.showCloudScheduleBackOffice) ||
  (isWarehouse() && store.getters.showCloudScheduleWarehouse))
}

const redirectToFeedbackSite = () => {
  const email = store.getters.userName
  const orgId = localStorage.getItem('OrgId')
  const sessionId = localStorage.getItem('SessionId')
  const isImpersonation = store.getters.isSuperUser
  const path = encodeURIComponent(router.app._route.fullPath)
  const query = `?Email=${email}&OrgId=${orgId}&IsImpersonation=${isImpersonation}&SessionId=${sessionId}&Page=${path}`
  const feedbackUrl = `https://aligntech.getfeedback.com/r/ogAI1Fdv${query}`
  window.open(feedbackUrl, '_blank')
  router.push('/')
}

const redirectToWhatsNew = () => {
  const whatsNewUrl = 'https://www.toolwatch.com/product/updates'
  window.open(whatsNewUrl, '_blank')
  router.push('/')
}

const routes = [
  {
    path: '/login',
    component: PublicLayout,
    beforeEnter: async (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next()
      }
    },
    children: [
      {
        path: '',
        name: 'Login',
        component: Login
      }
    ]
  },
  {
    path: '/platform-login',
    component: PublicLayout,
    beforeEnter: async (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next()
      }
    },
    children: [
      {
        path: '',
        name: 'PlatformLogin',
        component: PlatformLogin
      }
    ]
  },
  {
    path: '/',
    component: AppLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'Dashboard',
        meta: {
          title: 'System Dashboard'
        },
        exact: true,
        component: DashboardPage,
        beforeEnter: async (to, from, next) => {
          if (isEHSOnly()) {
            next('/ehs/dashboards')
          } else {
            next()
          }
        }
      },
      {
        path: 'requisitions',
        component: EmptyRouterView,
        beforeEnter: async (to, from, next) => {
          const { guard, entitlement } = getMatchedRoute('Requisitions Home')
          if (!isEHSOnly() && isJobsitePortal() && hasEntitledPermission(entitlement, guard)) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        children: [
          {
            path: '',
            name: 'Requisitions Home',
            meta: {
              title: 'Requistions'
            },
            component: ReqHome
          },
          {
            path: 'dashboard',
            name: 'Requisition Dashboard',
            meta: {
              title: 'Requisitions Dashboard'
            },
            component: DashboardPage
          },
          {
            path: 'requests/:id',
            name: 'Request History',
            component: Requests
          },
          {
            path: 'search',
            name: 'Requisitions Search',
            component: RequisitionsSearch
          },
          {
            path: 'productdetail/:id',
            name: 'ProductDetail',
            component: ProductDetail
          },
          {
            path: 'cart',
            name: 'Cart',
            component: Cart
          },
          {
            path: 'checkout',
            name: 'Checkout',
            component: Checkout,
            beforeEnter: async (to, from, next) => {
              if (somePermitted(['RequisitionsandReturnsEdit'])) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          }
        ]
      },
      {
        path: 'returns',
        component: EmptyRouterView,
        beforeEnter: async (to, from, next) => {
          const { guard, entitlement } = getMatchedRoute('Returns Home')
          if (!isEHSOnly() && isJobsitePortal() && hasEntitledPermission(entitlement, guard)) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        children: [
          {
            path: '',
            name: 'Returns Home',
            meta: {
              title: 'Returns'
            },
            component: ReturnHome
          },
          {
            path: 'history',
            name: 'Returns History',
            meta: {
              title: 'Return Home'
            },
            component: ReturnHistory
          },
          {
            path: 'search',
            name: 'Returns search',
            component: ReturnSearch
          },
          {
            path: 'ticket',
            name: 'Returns ticket',
            component: ReturnTicket
          }
        ]
      },
      {
        path: 'reservations',
        component: EmptyRouterView,
        beforeEnter: async (to, from, next) => {
          const { guard, entitlement } = getMatchedRoute('Scheduling Home')
          if (!isEHSOnly() && hasEntitledPermission(entitlement, guard)) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        children: [
          {
            path: '',
            name: 'Scheduling Home',
            component: ReservationHome,
            beforeEnter: async (to, from, next) => {
              if (isShowScheduling()) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'history/:id',
            name: 'Scheduling History',
            component: ReservationHistory,
            beforeEnter: async (to, from, next) => {
              if (isShowScheduling()) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'search',
            name: 'Reservations Search',
            component: ReservationSearch,
            beforeEnter: async (to, from, next) => {
              if (isShowScheduling()) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'productdetail/:id',
            name: 'Reservation ProductDetail',
            component: ReservationProductDetail,
            beforeEnter: async (to, from, next) => {
              if (isShowScheduling()) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'cart',
            name: 'Reservations Cart',
            component: ReservationCart,
            beforeEnter: async (to, from, next) => {
              if (isShowScheduling()) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'checkout',
            name: 'Reservations Checkout',
            component: ReservationCheckout,
            beforeEnter: async (to, from, next) => {
              if (isShowScheduling()) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          }
        ]
      },
      {
        path: 'scheduling',
        component: EmptyRouterView,
        beforeEnter: async (to, from, next) => {
          const { guard, entitlement } = getMatchedRoute('Scheduling Request')
          if (!isEHSOnly() && hasEntitledPermission(entitlement, guard)) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        children: [
          {
            path: 'scheduling-browser',
            name: 'Scheduling Browser',
            component: SchedulingBrowser,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Scheduling Browser')
              if (hasEntitledPermission(entitlement, guard) && store.getters.isShowScheduling) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'scheduling-approve-reject',
            name: 'Scheduling Approve Reject',
            component: SchedulingApproveReject
          },
          {
            path: 'scheduling-request',
            name: 'Scheduling Request',
            component: SchedulingRequestNew,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Scheduling Request')
              if (hasEntitledPermission(entitlement, guard) && store.getters.isShowScheduling) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'scheduling-calendar',
            name: 'Scheduling Calendar',
            component: SchedulingCalendar,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Scheduling Calendar')
              if (hasEntitledPermission(entitlement, guard) && store.getters.isShowScheduling) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'scheduling-cart',
            name: 'Scheduling Cart',
            component: SchedulingCart
          },
          {
            path: 'checkout',
            name: 'Scheduling Checkout',
            component: SchedulingCheckout
          }
        ]
      },
      {
        path: 'location-map',
        name: 'Map - NEW!',
        component: LocationMap,
        beforeEnter: async (to, from, next) => {
          const { guard, entitlement } = getMatchedRoute('Map - NEW!')
          if (!isEHSOnly() && hasEntitledPermission(entitlement, guard) && store.getters.isViewGoogleMap) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        }
      },
      {
        path: 'location-map',
        name: 'Map',
        component: LocationMap,
        beforeEnter: async (to, from, next) => {
          const { guard, entitlement } = getMatchedRoute('Map - NEW!')
          if (!isEHSOnly() && hasEntitledPermission(entitlement, guard) && store.getters.isViewGoogleMap) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        }
      },
      {
        path: 'reports',
        component: EmptyRouterView,
        beforeEnter: async (to, from, next) => {
          if (!isEHSOnly() && (isBackoffice() || isWarehouse() || store.getters.showCloudWorkInProgress)) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        children: [
          {
            path: '',
            name: 'Canned Reports',
            meta: {
              title: 'Report Chooser'
            },
            component: CannedReports
          },
          {
            path: 'custom',
            name: 'Custom Reports',
            component: CustomReports,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Custom Reports')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                router.push(from.path)
              }
            }
          },
          {
            path: 'scheduled',
            name: 'Scheduled Reports',
            component: ScheduledReports,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Scheduled Reports')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                router.push(from.path)
              }
            }
          },
          {
            path: 'dashboards',
            name: 'Custom Dashboards',
            component: CustomDashboards,
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowReportCustomDashboard) {
                next()
              } else {
                router.push(from.path)
              }
            }
          },
          {
            path: 'dashboards',
            name: 'Manage Dashboards',
            component: CustomDashboards,
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowReportCustomDashboard) {
                next()
              } else {
                router.push(from.path)
              }
            }
          },
          {
            path: 'edit',
            name: 'Edit Report',
            component: EditReports
          },
          {
            path: 'edit-dashboard',
            name: 'Edit Custom Dashboard',
            component: EditDashboard
          }
        ]
      },
      {
        path: 'tasks',
        component: EmptyRouterView,
        beforeEnter: async (to, from, next) => {
          const { guard, entitlement } = getMatchedRoute('Tasks')
          if (!isEHSOnly() && hasEntitledPermission(entitlement, guard) && store.getters.isShowTasks) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        children: [
          {
            path: '',
            name: 'Tasks',
            component: TasksHome
          }
        ]
      },
      {
        path: 'catalog',
        component: CatalogLayout,
        beforeEnter: async (to, from, next) => {
          const { guard, entitlement } = getMatchedRoute('Catalog')
          if (!isEHSOnly() && hasEntitledPermission(entitlement, guard)) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        children: [
          {
            path: '',
            name: 'Catalog',
            meta: {
              title: 'Product Catalog'
            },
            component: CatalogHome
          },
          {
            path: 'detail',
            name: 'Catalog Detail',
            meta: {
              title: 'Product Detail'
            },
            component: CatalogDetail
          },
          {
            path: 'search',
            name: 'Catalog Search',
            meta: {
              title: 'Product Catalog'
            },
            component: CatalogsView
          }
        ]
      },
      {
        path: 'items',
        component: EmptyRouterView,
        beforeEnter: async (to, from, next) => {
          const { guard, entitlement } = getMatchedRoute('Item Browser')
          if (!isEHSOnly() && hasEntitledPermission(entitlement, guard)) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        children: [
          {
            path: '',
            name: 'Item Browser',
            component: ItemBrowser
          }
        ]
      },
      {
        path: 'job-stock',
        component: EmptyRouterView,
        beforeEnter: async (to, from, next) => {
          if (!isEHSOnly() && store.getters.isShowJobStock && hasEntitledPermission('ToolsJobStock', ['JobStockView'])) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        children: [
          {
            path: '',
            name: 'Job Stock',
            component: JobStock
          },
          {
            path: 'po-add',
            name: 'POAddItem',
            component: POAddItem,
            props: { isJobStockService: true }
          },
          {
            path: 'add-model',
            name: 'jobManagerAddModel',
            component: POAddItem,
            props: { isJobStockService: true }
          }
        ]
      },
      {
        path: 'equipments',
        component: EmptyEquRouterView,
        beforeEnter: async (to, from, next) => {
          if (!isEHSOnly() && store.getters.isShowEquipmentNav) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        children: [
          {
            path: '',
            name: 'Equipment Browser',
            component: ItemBrowser,
            props: { isEquipmentService: true }
          }
        ]
      },
      {
        path: 'kit',
        component: KitRouterView,
        beforeEnter: async (to, from, next) => {
          if (!isEHSOnly() && store.getters.userPermission.includes('KitsView')) {
            next()
          } else {
            router.push(from.path)
          }
        },
        children: [
          {
            path: 'detail',
            name: 'Kit Detail',
            component: KitDetail
          }
        ]
      },
      {
        path: 'low-level',
        component: EmptyRouterView,
        beforeEnter: async (to, from, next) => {
          const { guard, entitlement } = getMatchedRoute('Low Level Browser')
          if (!isEHSOnly() && hasEntitledPermission(entitlement, guard)) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        children: [
          {
            path: '',
            name: 'Low Level Browser',
            component: LowLevelBrowser
          }
        ]
      },
      {
        path: 'workflow',
        component: EmptyRouterView,
        beforeEnter: async (to, from, next) => {
          if (!isEHSOnly() && (store.getters.isShowEnhancedWorkflows ||
            store.getters.isShowTasks ||
            store.getters.isShowWorkflowApprovals)) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        children: [
          {
            path: 'task-browser',
            name: 'Task Browser',
            component: TasksHome,
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowTasks) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'active-workflow',
            name: 'Active Workflows',
            component: WorkflowBrowser,
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowEnhancedWorkflows) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'workflow-browser',
            name: 'Workflow Browser',
            component: WorkflowBuilder,
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowEnhancedWorkflows) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'workflow-new',
            name: 'New Workflow',
            component: WorkflowNew,
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowEnhancedWorkflows) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'workflow-edit',
            name: 'Edit Workflow',
            component: WorkflowNew,
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowEnhancedWorkflows) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'workflow-view',
            name: 'View Workflow',
            component: WorkflowNew,
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowEnhancedWorkflows) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'workflow-copy',
            name: 'Copy Workflow',
            component: WorkflowNew,
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowEnhancedWorkflows) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'workflow-approvals',
            name: 'Approvals',
            component: WorkflowApprovals,
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowWorkflowApprovals) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          }
        ]
      },
      {
        path: 'retired-tools',
        component: EmptyRouterView,
        beforeEnter: async (to, from, next) => {
          const { guard, entitlement } = getMatchedRoute('Manage Retired Tools')
          if (!isEHSOnly() && hasEntitledPermission(entitlement, guard) &&
            (isWarehouse() || store.getters.showCloudWorkInProgress)) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        children: [
          {
            path: '',
            name: 'Manage Retired Tools',
            meta: {
              title: 'Retired Tools'
            },
            component: RetiredTools
          }
        ]
      },
      {
        path: 'administration',
        component: EmptyRouterView,
        beforeEnter: async (to, from, next) => {
          next()
        },
        children: [
          {
            path: '',
            redirect: 'ble-gateways'
          },
          {
            path: 'ble-gateways',
            name: 'Wireless Gateways',
            component: BleSensorsPage,
            beforeEnter: async (to, from, next) => {
              if (!isEHSOnly()) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'ble-tags',
            name: 'Wireless Tags',
            component: BleTagsPage,
            beforeEnter: async (to, from, next) => {
              if (!isEHSOnly()) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          // This page needs to be accessible from EHS. There is followup work to make it a link?? in EHS.
          {
            path: 'user-preferences',
            name: 'User Preferences',
            component: UserPreferencesPage
          },
          {
            path: 'company-settings',
            name: 'Company Settings',
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Company Settings')
              if (!isEHSOnly() && hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                router.push(from.path)
              }
            },
            component: OrgsettingsPage
          },
          {
            path: 'company-calendar',
            name: 'Company Calendar',
            meta: {
              title: 'Company Default Calender'
            },
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Company Calendar')
              if (!isEHSOnly() && hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            },
            component: CompanyCalendarPage
          },
          {
            path: 'data-loader',
            name: 'Data Loader',
            component: DataLoader,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Data Loader')
              if (!isEHSOnly() && hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'custom-fields',
            name: 'Custom Fields',
            component: CustomFields,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Custom Fields')
              if (!isEHSOnly() && hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'integration',
            name: 'Integration',
            component: EmptyRouterView,
            beforeEnter: async (to, from, next) => {
              if (!isEHSOnly()) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            },
            children: [
              {
                path: 'catalog',
                name: 'Integration Catalog',
                component: IntegrationCatalogPage
              },
              {
                path: 'settings',
                name: 'Integration Settings',
                meta: {
                  title: 'My Settings'
                },
                component: IntegrationSettingsPage
              }
            ]
          },
          {
            path: 'import-meter',
            name: 'Import Meter',
            component: ImportMeter,
            beforeEnter: async (to, from, next) => {
              if (!isEHSOnly()) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'sensors',
            name: 'Sensors',
            component: BleCombinedPage,
            beforeEnter: async (to, from, next) => {
              if (!isEHSOnly()) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'configure-user-roles',
            name: 'Configure User Roles',
            meta: {
              title: 'Roles Management'
            },
            component: ConfigureUserRolesSwitch,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Configure User Roles')
              if (!isEHSOnly() && hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          // TODO: Refactor this route once the new user roles page is ready
          {
            path: 'configure-user-roles/:roleId',
            name: 'Users Assigned To Role',
            component: UsersAssignedToRole,
            beforeEnter: async (to, from, next) => {
              if (!isEHSOnly()) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'document-central',
            name: 'Document Central',
            component: DocumentCentral,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Document Central')
              if (!isEHSOnly() && hasEntitledPermission(entitlement, guard) && store.getters.isShowDocumentCentral) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'document-central/recycle-bin',
            name: 'Document Central Recycle Bin',
            meta: {
              title: 'Document Central Recycle Bin'
            },
            component: DocumentCentralRecycleBin,
            beforeEnter: async (to, from, next) => {
              if (!isEHSOnly()) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'safety-plan',
            name: 'Site Safety Plans',
            component: SafetyPlanHome,
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowSafetyPlan) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'numbering',
            name: 'Numbering',
            component: ListsNumberingPage,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Numbering')
              if (!isEHSOnly() && hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'list-visibility',
            name: 'List Visibility',
            component: ListsVisibilityPage,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('List Visibility')
              if (!isEHSOnly() && hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {

            path: 'lists',
            name: 'Lists',
            component: EmptyRouterView,
            beforeEnter: async (to, from, next) => {
              if (!isEHSOnly()) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            },
            children: [
              {
                path: 'descriptions',
                name: 'Descriptions',
                component: ListsDescriptionsPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Descriptions')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'data-merge-utility',
                name: 'Data Merge Utility',
                meta: {
                  title: 'Data Merger'
                },
                component: ListsDataMergeUtility,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Data Merge Utility')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'categories',
                name: 'Categories',
                component: ListsCategoryPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Categories')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'certifications',
                name: 'Certifications',
                component: ListsCertificationPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Certifications')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'classes',
                name: 'Classes',
                component: ListsClassesPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Classes')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'departments',
                name: 'Departments',
                component: ListsDepartmentsPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Departments')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'manufacturers',
                name: 'Manufacturers',
                component: ListsManufacturersPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Manufacturers')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'shipping-methods',
                name: 'Shipping Methods',
                component: ListsShippingMethodsPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Shipping Methods')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'locations',
                name: 'Locations',
                component: ListsLocationsPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Locations')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'cost-centers',
                name: 'Cost Centers',
                component: ListsCostCentersPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Cost Centers')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'cost-codes',
                name: 'Cost Codes',
                component: ListsCostCodesPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Cost Codes')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'status-codes',
                name: 'Status Codes',
                component: ListsStatusCodesPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Status Codes')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'vendors',
                name: 'Vendors',
                component: ListsVendorsPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Vendors')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'tasks-categories',
                name: 'Task Categories',
                component: ListsTaskCategoriesPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Task Categories')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'tasks-priorities',
                name: 'Task Priorities',
                component: ListsTaskPrioritiesPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Task Priorities')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'tasks-statuses',
                name: 'Task Statuses',
                component: ListsTaskStatusesPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Task Statuses')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'contractors',
                name: 'Contractors',
                component: ListsContractorsPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Contractors')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'customers',
                name: 'Customers',
                component: ListsCustomersPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Customers')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'employees',
                name: 'Employees',
                component: ListsEmployeesPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Employees')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'employee-types',
                name: 'Employee Types',
                component: ListsEmployeeTypesPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Employee Types')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'units-of-measure',
                name: 'Units of Measure',
                component: ListsUnitsOfMeasurePage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Units of Measure')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'service-classes',
                name: 'Service Classes',
                component: ListsServiceClassesPage
              },
              {
                path: 'Fuel-cards',
                name: 'Fuel Cards',
                component: FuelCards,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Fuel Cards')
                  if (hasEntitledPermission(entitlement, guard) && store.getters.isShowFuelCards) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'fuel-cards-create',
                name: 'Cards Item Creation',
                meta: {
                  title: 'Fuel Cards Add Items'
                },
                component: FuelCardsItem,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Fuel Cards')
                  if (hasEntitledPermission(entitlement, guard) && store.getters.isShowFuelCards) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'fuel-employees',
                name: 'Fuel Employees',
                component: FuelEmployees,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Fuel Employees')
                  if (hasEntitledPermission(entitlement, guard) && store.getters.isShowFuelCards) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'fuel-import-employees',
                name: 'Fuel Import Employees',
                meta: {
                  title: 'Fuel Import Employees'
                },
                component: FuelImportEmployees
              },
              {
                path: 'fuel-card-import',
                name: 'Fuel Card Import',
                meta: {
                  title: 'Fuel Card Import'
                },
                component: FuelCardImport,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Fuel Cards')
                  if (hasEntitledPermission(entitlement, guard) && store.getters.isShowFuelCards) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              }
            ]
          }
        ]
      },
      {
        path: 'tickets',
        component: TicketRouterView,
        beforeEnter: async (to, from, next) => {
          if (!isEHSOnly() && (isWarehouse() || store.getters.showCloudWorkInProgress)) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        children: [
          {
            path: 'transfers',
            name: 'Transfer Tickets',
            component: TransferTicket,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Transfer Tickets')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'transfers/create',
            name: 'Transfer Creation',
            meta: {
              title: 'Transfer Add Items'
            },
            component: TransferTicketCreate
          },
          {
            path: 'transfers/process',
            name: 'Transfer Holder',
            meta: {
              title: 'Transfer'
            },
            component: TransferHolder
          },
          {
            path: 'dashboard',
            name: 'Assignments Dashboard',
            meta: {
              title: 'Pick Tickets & Transfers Dashboard'
            },
            component: DashboardPage
          },
          {
            path: 'templates',
            name: 'Templates',
            meta: {
              title: 'Template Browser'
            },
            component: Templates
          },
          {
            path: 'templates/create',
            name: 'CreateTemplates',
            meta: {
              title: 'Template Browser'
            },
            component: TemplateCreate
          },
          {
            path: 'templates/edit',
            name: 'EditTemplates',
            meta: {
              title: 'Template Detail'
            },
            component: TemplateHolder
          },
          {
            path: 'picks',
            name: 'Pick Tickets',
            meta: {
              title: 'Pick Ticket Browser'
            },
            component: PickTicket,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Pick Tickets')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'picks/create',
            name: 'Pick Tickets Create',
            meta: {
              title: 'Pick Ticket'
            },
            component: PickTicketCreate
          },
          {
            path: 'picks/process',
            name: 'Pick Holder',
            meta: {
              title: 'Pick Ticket'
            },
            component: PickHolder
          },
          {
            path: 'count-inventory',
            name: 'Count Inventory',
            meta: {
              title: 'Count Ticket Browser'
            },
            component: CountInventory,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Count Inventory')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'count-inventory',
            name: 'Count Tickets',
            meta: {
              title: 'Count Ticket Browser'
            },
            component: CountInventory,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Count Inventory')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'count-inventory/create',
            name: 'Create Count Tickets',
            meta: {
              title: 'Count Ticket'
            },
            component: CreateCountTickets
          },
          {
            path: 'count-inventory/edit',
            name: 'Edit Count Tickets',
            meta: {
              title: 'Count Ticket'
            },
            component: CreateCountTickets
          },
          {
            path: 'inventory-adjustments-browser',
            name: 'InventoryAdjustmentsBrowser',
            component: InventoryAdjustmentsBrowser
          },
          {
            path: 'tool-record',
            name: 'countTicketToolRecord',
            component: CountTicketToolRecord
          },
          {
            path: 'requisition-browser',
            name: 'Requisition Browser',
            component: RequisitionRequestBrowser,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Pick Tickets')
              if (hasEntitledPermission(entitlement, guard) && store.getters.isShowRequisitionBrowser) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'requisition-browser/View',
            name: 'RequisitionBrowserView',
            component: RequisitionBrowserView,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Pick Tickets')
              if (hasEntitledPermission(entitlement, guard) && store.getters.isShowRequisitionBrowser) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'requisition-browser/create',
            name: 'RequisitionRequestBrowserCreate',
            component: RequisitionRequestBrowserCreate,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Pick Tickets')
              if (hasEntitledPermission(entitlement, guard) && store.getters.isShowRequisitionBrowser) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          }
        ]
      },
      {
        path: 'service',
        component: ServiceCalibrationRouterView,
        beforeEnter: async (to, from, next) => {
          if (!isEHSOnly()) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        children: [
          {
            path: 'dashboard',
            name: 'Service Dashboard',
            meta: {
              title: 'Service and Calibration Dashboard'
            },
            component: DashboardPage
          },
          {
            path: 'requests',
            name: 'Service Requests',
            meta: {
              title: 'Service Request Browser'
            },
            component: ServiceRequestBrowser,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Service Requests')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'request/edit',
            name: 'Edit Service Requests',
            meta: {
              title: 'Service Request'
            },
            component: EditServiceRequest
          },
          {
            path: 'requests/add-items',
            name: 'Add Items To Service Request',
            meta: {
              title: 'Service Requests'
            },
            component: AddItemsToServiceRequest
          },
          {
            path: 'work-orders',
            name: 'Work Orders',
            meta: {
              title: 'work Orders Browser'
            },
            component: WorkOrders,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Work Orders')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'work-orders/edit',
            name: 'Work Orders Edit',
            meta: {
              title: 'Work Order'
            },
            component: WorkOrdersEdit
          },
          {
            path: 'service-schedule',
            name: 'Browse Service Schedule',
            component: BrowseServiceSchedule,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Browse Service Schedule')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          }
        ]
      },
      {
        path: 'purchasing',
        component: PurchaseRouterView,
        beforeEnter: async (to, from, next) => {
          if (!isEHSOnly() && (isWarehouse() || store.getters.showCloudWorkInProgress)) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        children: [
          {
            path: 'dashboard',
            name: 'Purchasing Dashboard',
            component: DashboardPage
          },
          {
            path: 'receiving',
            name: 'Receiving Tickets',
            meta: {
              title: 'Receiving Browser'
            },
            component: ReceivingTickets,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Receiving Tickets')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'receiving/detail',
            name: 'Receiving Ticket Detail',
            component: ReceivingTicketDetail
          },
          {
            path: 'purchase-orders',
            name: 'PurchaseOrders',
            meta: {
              title: 'Purchase Order Browser'
            },
            component: PurchaseOrders,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('PurchaseOrders')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'purchase-detail',
            name: 'PurchaseOrderDetail',
            component: PurchaseOrderDetail
          },
          {
            path: 'po-add',
            name: 'POAddItem',
            component: POAddItem
          }
        ]
      },
      {
        path: 'billing',
        component: EmptyRouterView,
        beforeEnter: async (to, from, next) => {
          if (!isEHSOnly() && (isBackoffice() || store.getters.showCloudWorkInProgress)) {
            next()
          } else {
            if (to.name == 'Equipment Usage Log' &&
              (!isEHSOnly() && (isJobsitePortal() || store.getters.showCloudWorkInProgress))) {
              next()
            } else {
              try {
                await router.push(from.path)
              } catch (error) { }
            }
          }
        },
        children: [
          {
            path: '',
            name: 'Billing Browser',
            component: BillingBrowser,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Billing Browser')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'fuel-transaction-browser',
            name: 'Fuel Transaction Browser',
            component: FuelTransactionBrowser,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Fuel Transaction Browser')
              if (hasEntitledPermission(entitlement, guard) && store.getters.isShowFuelCards) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'import-fuel-transaction',
            name: 'Import Fuel Transaction',
            meta: {
              title: 'Import Fuel Transaction'
            },
            component: ImportFuelTransaction,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Fuel Transaction Browser')
              if (hasEntitledPermission(entitlement, guard) && store.getters.isShowFuelCards) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'rateSheets',
            name: 'Rate Sheets',
            meta: {
              title: 'Rate Sheet Browser'
            },
            component: RateSheets
          },
          {
            path: 'ratesheets/view',
            name: 'Rate Sheets View',
            meta: {
              title: 'Rate Sheet'
            },
            component: RateSheetsView,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Rate Sheets')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'equipment',
            name: 'Equipment Usage Log',
            component: EquipmentUsageLog,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Equipment Usage Log')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'equipment-add-item',
            name: 'Equipment Add Item',
            meta: {
              title: 'Tool Browser'
            },
            component: EquipmentUsageAddItem,
            beforeEnter: async (to, from, next) => {
              if (store.getters.userPermission.includes('HourlyUsageLogEdit')) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'export-data',
            name: 'Export Data',
            meta: {
              title: 'Data Export'
            },
            component: ExportData,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Export Data')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'invoice-browser',
            name: 'Invoice Browser',
            component: InvoiceBrowser,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Invoice Browser')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'invoice-browser-generate',
            name: 'Invoice Browser Generate',
            meta: {
              title: 'Generate Invoices'
            },
            component: InvoiceBrowserGenerate
          },
          {
            path: 'export-templates',
            name: 'Export Templates',
            meta: {
              title: 'Export Template Lists'
            },
            component: ExportTemplates,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Export Templates')
              if (hasEntitledPermission(entitlement, guard)) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          }
        ]
      },
      {
        path: 'recycle-bin',
        name: 'Recycle Bin',
        component: RecycleBin,
        beforeEnter: async (to, from, next) => {
          const { guard, entitlement } = getMatchedRoute('Recycle Bin')
          if (!isEHSOnly() && hasEntitledPermission(entitlement, guard) &&
            (isBackoffice() || isWarehouse() || store.getters.showCloudWorkInProgress)) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        }
      },
      {
        path: 'insights',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'Insights',
            component: InsightsPage,
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowInsights) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          }
        ]
      },
      {
        path: 'whatsnew',
        component: EmptyRouterView,
        name: 'What’s New',
        beforeEnter: async (to, from, next) => {
          if (!isEHSOnly()) {
            redirectToWhatsNew()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        }
      },
      {
        path: 'Feedback',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'Feedback',
            beforeEnter: async (to, from, next) => {
              if (!isEHSOnly()) {
                redirectToFeedbackSite()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          }
        ]
      },
      {
        path: 'Documentation',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'Documentation',
            beforeEnter: async (to, from, next) => {
              if (!store.getters.isSupportRedesign) {
                redirectToHelpSite()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'online-documentation',
            name: 'Online Documentation',
            beforeEnter: async (to, from, next) => {
              if (!store.getters.isSupportRedesign) {
                redirectToHelpSite()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          }
        ]
      },
      {
        path: 'getSupport',
        component: EmptyRouterView,
        name: 'Get Support',
        beforeEnter: async (to, from, next) => {
          if (!store.getters.isSupportRedesign) {
            EventBus.$emit('openIntercom')
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        }
      },
      {
        path: 'safety',
        component: EmptyRouterView,
        children: [
          {
            path: '/admin',
            name: 'Admin',
            component: SafetyPage,
            props: { route: '/admin/adminMPV2' },
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowSafetyMenu) {
                redirectToEHSAdmin()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: '/inspections',
            name: 'Inspections',
            component: SafetyPage,
            props: { route: '/fullview' },
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowSafetyMenu) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: '/jsa',
            name: 'JSA',
            component: SafetyPage,
            props: { route: '/jsa' },
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowSafetyMenu) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: '/observations',
            name: 'Observations',
            component: SafetyPage,
            props: { route: '/observations' },
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowSafetyMenu) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: '/incidents',
            name: 'Incidents',
            component: SafetyPage,
            props: { route: '/incidents' },
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowSafetyMenu) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: '/scan',
            name: 'Scan',
            component: SafetyPage,
            props: { route: '/safetyscan' },
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowSafetyMenu) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: '/forms',
            name: 'Forms',
            component: SafetyPage,
            props: { route: '/srforms' },
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowSafetyMenu) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          }
        ]
      },
      {
        path: 'ehs',
        component: EmptyRouterView,
        children: [
          // Dashboards
          {
            path: 'dashboards',
            component: EmptyRouterView,
            children: [
              {
                path: '',
                name: 'Dashboards',
                component: SafetyPage,
                props: { route: '/admin/adminMPV2?pId=1', dashboardNav: 'EHSDashboards' }
              },
              {
                path: '',
                name: 'Inspection Dashboard',
                component: SafetyPage,
                props: { route: '/admin/adminMPV2?pId=1', dashboardNav: 'EHSDashboards' }
              },
              {
                path: 'training-dashboard',
                name: 'Training Dashboard',
                component: SafetyPage,
                props: { route: '/admin/adminMPV2?pId=3', dashboardNav: 'EHSDashboards' }
              },
              {
                path: 'incidents-dashboard',
                name: 'Incidents Dashboard',
                component: SafetyPage,
                props: { route: '/admin/adminMPV2?pId=6', dashboardNav: 'EHSDashboards' }
              },
              {
                path: 'observations-dashboard',
                name: 'Observations Dashboard',
                component: SafetyPage,
                props: { route: '/admin/adminMPV2?pId=5', dashboardNav: 'EHSDashboards' }
              },
              {
                path: 'jsa-dashboard',
                name: 'JSA Dashboard',
                component: SafetyPage,
                props: { route: '/admin/adminMPV2?pId=4', dashboardNav: 'EHSDashboards' }
              },
              {
                path: 'safety-scan-dashboard',
                name: 'Safety Scan Dashboard',
                component: SafetyPage,
                props: { route: '/admin/adminMPV2?pId=7', dashboardNav: 'EHSDashboards' }
              },
              {
                path: 'forms-dashboard',
                name: 'Forms Dashboard',
                component: SafetyPage,
                props: { route: '/admin/adminMPV2?pId=9', dashboardNav: 'EHSDashboards' }
              }
            ]
          },
          // EHS Modules
          {
            path: 'modules',
            component: EmptyRouterView,
            children: [
              {
                path: 'inspection-web-module',
                name: 'Inspection Web Module',
                component: SafetyPage,
                props: { route: '/fullview', ignoreMargins: true }
              },
              // {
              //   path: 'training-web-module',
              //   name: 'Training Web Module',
              //   component: SafetyPage,
              //   props: { route: '/toadd' }
              // },
              {
                path: 'incidents-web-module',
                name: 'Incidents Web Module',
                component: SafetyPage,
                props: { route: '/incidents', ignoreMargins: true }
              },
              {
                path: 'observations-web-module',
                name: 'Observations Web Module',
                component: SafetyPage,
                props: { route: '/observations', ignoreMargins: true }
              },
              {
                path: 'jsa-web-module',
                name: 'JSA Web Module',
                component: SafetyPage,
                props: { route: '/jsa', ignoreMargins: true }
              },
              {
                path: 'safety-scan-web-module',
                name: 'Safety Scan Web Module',
                component: SafetyPage,
                props: { route: '/safetyscan', ignoreMargins: true }
              },
              {
                path: 'forms-web-module',
                name: 'Forms Web Module',
                component: SafetyPage,
                props: { route: '/srforms', ignoreMargins: true }
              }
            ]
          },
          // Inspections
          {
            path: 'inspections',
            component: EmptyRouterView,
            children: [
              {
                path: 'inspection-maintenance',
                name: 'Inspection Maintenance',
                component: SafetyPage,
                props: { route: '/admin/jobsMaintV3' }
              },
              {
                path: 'job-bank-maintenance',
                name: 'Job Bank Maintenance',
                component: SafetyPage,
                props: { route: '/admin/JobBankMaintV3' }
              },
              {
                path: 'standard-checklist-maintenance',
                name: 'Standard Checklist Maintenance',
                component: SafetyPage,
                props: { route: '/admin/CustomizeListV3' }
              },
              {
                path: 'custom-checklist-maintenance',
                name: 'Custom Checklist Maintenance',
                component: SafetyPage,
                props: { route: '/admin/CategoryMaintV3' }
              },
              {
                path: 'corrective-action-tracking',
                name: 'Corrective Action Tracking',
                component: SafetyPage,
                props: { route: '/admin/CorActTrkAdminV3' }
              },
              {
                path: 'remote-sessions',
                name: 'Remote Sessions',
                component: SafetyPage,
                props: { route: '/admin/RemoteSessionsAdminV3' }
              },
              {
                path: 'inspection-settings',
                name: 'Inspection Settings',
                component: SafetyPage,
                props: { route: '/admin/AccountSettingsMaintV3' }
              }
            ]
          },
          // Training
          {
            path: 'training',
            component: EmptyRouterView,
            children: [
              {
                path: 'training-events',
                name: 'Training Events',
                component: SafetyPage,
                props: { route: '/admin/TrainingEventsMaintV2' }
              },
              {
                path: 'training-tracking',
                name: 'Training Tracking',
                component: SafetyPage,
                props: { route: '/admin/TrainingTrackingV3' }
              },
              {
                path: 'tool-box-talks',
                name: 'ToolBoxTalks',
                component: SafetyPage,
                props: { route: '/admin/ToolBoxTalksMaintV3' }
              },
              {
                path: 'training-history',
                name: 'Training History',
                component: SafetyPage,
                props: { route: '/admin/TrainingHistoryV2' }
              },
              {
                path: 'required-trainings',
                name: 'Required Trainings',
                component: SafetyPage,
                props: { route: '/admin/TrainingRequiredTrainingsV3' }
              },
              {
                path: 'training-exam',
                name: 'Training Exam',
                component: SafetyPage,
                props: { route: '/admin/TrainingReviewV3' }
              },
              {
                path: 'training-confirmation',
                name: 'Training Confirmation',
                component: SafetyPage,
                props: { route: '/admin/TrainingConfirmationV3' }
              },
              {
                path: 'training-settings',
                name: 'Training Settings',
                component: SafetyPage,
                props: { route: '/admin/TrainingSettingsV2' }
              }
            ]
          },
          // Incidents
          {
            path: 'incidents',
            component: EmptyRouterView,
            children: [
              {
                path: 'incident-maintenance',
                name: 'Incident Maintenance',
                component: SafetyPage,
                props: { route: '/admin/IncidentAdminV3' }
              },
              {
                path: 'osha-301',
                name: 'OSHA 301',
                component: SafetyPage,
                props: { route: '/admin/Incident301AdminV3' }
              },
              {
                path: 'osha-300-log',
                name: 'OSHA 300 Log',
                component: SafetyPage,
                props: { route: '/admin/Incident300LogAdminV3' }
              },
              {
                path: 'behavior-maintenance',
                name: 'Behavior Maintenance',
                component: SafetyPage,
                props: { route: '/admin/IncidentBehaviorV3' }
              },
              {
                path: 'conditions-maintenance',
                name: 'Conditions Maintenance',
                component: SafetyPage,
                props: { route: '/admin/IncidentConditionV3' }
              },
              {
                path: 'entity-maintenance',
                name: 'Entity Maintenance',
                component: SafetyPage,
                props: { route: '/admin/IncidentEntityMaintV3' }
              },
              {
                path: 'incident-settings',
                name: 'Incident Settings',
                component: SafetyPage,
                props: { route: '/admin/IncidentSettingsV3' }
              }
            ]
          },
          // Observations
          {
            path: 'observations',
            component: EmptyRouterView,
            children: [
              {
                path: 'observation-maintenance',
                name: 'Observation Maintenance',
                component: SafetyPage,
                props: { route: '/admin/ObsAdminV3' }
              },
              {
                path: 'category-maintenance',
                name: 'Category Maintenance',
                component: SafetyPage,
                props: { route: '/admin/ObsCategoryMaintV3' }
              },
              {
                path: 'contributing-factor-maintenance',
                name: 'Contributing Factor Maintenance',
                component: SafetyPage,
                props: { route: '/admin/ObsCauseMaintV3' }
              },
              {
                path: 'observation-settings',
                name: 'Observation Settings',
                component: SafetyPage,
                props: { route: '/admin/ObsSettingsV3' }
              }
            ]
          },
          // Job Safety Analysis
          {
            path: 'job-safety-analysis',
            component: EmptyRouterView,
            children: [
              {
                path: 'jsa-maintenance',
                name: 'JSA Maintenance',
                component: SafetyPage,
                props: { route: '/admin/JSAMaintV3' }
              },
              {
                path: 'jsa-bank-maintenance',
                name: 'JSA Bank Maintenance',
                component: SafetyPage,
                props: { route: '/admin/jsaBankMaintv3' }
              },
              {
                path: 'hazards-maintenance',
                name: 'Hazards Maintenance',
                component: SafetyPage,
                props: { route: '/admin/JSAHazardsMaintV3' }
              },
              {
                path: 'controls-maintenance',
                name: 'Controls Maintenance',
                component: SafetyPage,
                props: { route: '/admin/JSAControlsMaintV3' }
              },
              {
                path: 'classification-maintenance',
                name: 'Classification Maintenance',
                component: SafetyPage,
                props: { route: '/admin/jsaGroupMaintv3' }
              },
              {
                path: 'ppe-maintenance',
                name: 'PPE Maintenance',
                component: SafetyPage,
                props: { route: '/admin/JSAPPEV3' }
              },
              {
                path: 'required-training-maintenance',
                name: 'Required Training Maintenance',
                component: SafetyPage,
                props: { route: '/admin/JSARequiredTrainingV3' }
              },
              {
                path: 'chemical-maintenance',
                name: 'Chemical Maintenance',
                component: SafetyPage,
                props: { route: '/admin/JSAChemicalsMaintV3' }
              },
              {
                path: 'jsa-settings',
                name: 'JSA Settings',
                component: SafetyPage,
                props: { route: '/admin/jsaSettingsV3' }
              }
            ]
          },
          // Safety Scan
          {
            path: 'safety-scan',
            component: EmptyRouterView,
            children: [
              {
                path: 'assets',
                name: 'Assets',
                component: SafetyPage,
                props: { route: '/admin/EqpAssetMaintV3' }
              },
              {
                path: 'assets-types',
                name: 'Asset Types',
                component: SafetyPage,
                props: { route: '/admin/EqpAssetTypeMaintV3' }
              },
              {
                path: 'checklists',
                name: 'Checklists',
                component: SafetyPage,
                props: { route: '/admin/EqpChecklistMaintV3' }
              },
              {
                path: 'generate-qr-codes',
                name: 'Generate QR Codes',
                component: SafetyPage,
                props: { route: '/admin/EqpGenBarCodeV3' }
              },
              {
                path: 'overview',
                name: 'Overview',
                component: SafetyPage,
                props: { route: '/admin/EqpOverview' }
              },
              {
                path: 'corrective-action-tracking',
                name: 'Corrective Action Tracking-Safety Scan',
                component: SafetyPage,
                props: { route: '/admin/EqpCorActTrkAdminV3' }
              },
              {
                path: 'routes',
                name: 'Routes',
                component: SafetyPage,
                props: { route: '/admin/EqpRouteMaintV3' }
              },
              {
                path: 'locations',
                name: 'Locations-Safety Scan',
                component: SafetyPage,
                props: { route: '/admin/EqpLocationMaintV3' }
              },
              {
                path: 'building-floor-area',
                name: 'Building, Floor & Area',
                component: SafetyPage,
                props: { route: '/admin/EqpBuildingMaintV3' }
              },
              {
                path: 'zone',
                name: 'Zone',
                component: SafetyPage,
                props: { route: '/admin/EqpZoneMaintV3' }
              },
              {
                path: 'division',
                name: 'Division',
                component: SafetyPage,
                props: { route: '/admin/EqpDivisionMaintV3' }
              },
              {
                path: 'safety-scan-settings',
                name: 'Safety Scan Settings',
                component: SafetyPage,
                props: { route: '/admin/EqpAccountsSettingsV3' }
              }
            ]
          },
          // Forms
          {
            path: 'forms',
            component: EmptyRouterView,
            children: [
              {
                path: 'forms-maintenance',
                name: 'Forms Maintenance',
                component: SafetyPage,
                props: { route: '/admin/FormsMaintV3' }
              },
              {
                path: 'completed-forms',
                name: 'Completed Forms',
                component: SafetyPage,
                props: { route: '/admin/FormsInspectionMaintV3' }
              },
              {
                path: 'corrective-action',
                name: 'Corrective Action',
                component: SafetyPage,
                props: { route: '/admin/FormsCorActTrkAdminV3' }
              },
              {
                path: 'forms-settings',
                name: 'Forms Settings',
                component: SafetyPage,
                props: { route: '/admin/FormsAccountsSettingsV3' }
              }
            ]
          },
          // Reports & Graphs
          {
            path: 'reports-graphs',
            component: EmptyRouterView,
            children: [
              {
                path: 'performance-reports',
                component: EmptyRouterView,
                children: [
                  {
                    path: 'inspection-performance-reports',
                    name: 'Inspection Performance Reports',
                    component: SafetyPage,
                    props: { route: '/admin/ReportsV3', dashboardNav: 'EHSPerformanceReports' }
                  },
                  {
                    path: 'training-performance-reports',
                    name: 'Training Performance Reports',
                    component: SafetyPage,
                    props: { route: '/admin/TrainingReportsV3', dashboardNav: 'EHSPerformanceReports' }
                  },
                  {
                    path: 'incidents-performance-reports',
                    name: 'Incidents Performance Reports',
                    component: SafetyPage,
                    props: { route: '/admin/incidentReportsV3', dashboardNav: 'EHSPerformanceReports' }
                  },
                  {
                    path: 'observations-performance-reports',
                    name: 'Observations Performance Reports',
                    component: SafetyPage,
                    props: { route: '/admin/ObsReportsV3', dashboardNav: 'EHSPerformanceReports' }
                  },
                  {
                    path: 'jsa-performance-reports',
                    name: 'JSA Performance Reports',
                    component: SafetyPage,
                    props: { route: '/admin/JSAReportsV3', dashboardNav: 'EHSPerformanceReports' }
                  },
                  {
                    path: 'safety-scan-performance-reports',
                    name: 'Safety Scan Performance Reports',
                    component: SafetyPage,
                    props: { route: '/admin/EqpReportsV3', dashboardNav: 'EHSPerformanceReports' }
                  },
                  {
                    path: 'forms-performance-reports',
                    name: 'Forms Performance Reports',
                    component: SafetyPage,
                    props: { route: '/admin/FormsReportsV3', dashboardNav: 'EHSPerformanceReports' }
                  }
                ]
              },
              {
                path: 'scheduled-reports',
                component: EmptyRouterView,
                children: [
                  {
                    path: 'inspection-scheduled-reports',
                    name: 'Inspection Scheduled Reports',
                    component: SafetyPage,
                    props: { route: '/admin/ScheduleReportsMaintV3?pId=1', dashboardNav: 'EHSScheduledReports' }
                  },
                  {
                    path: 'training-scheduled-reports',
                    name: 'Training Scheduled Reports',
                    component: SafetyPage,
                    props: { route: '/admin/ScheduleReportsMaintV3?pId=3', dashboardNav: 'EHSScheduledReports' }
                  },
                  {
                    path: 'incidents-scheduled-reports',
                    name: 'Incidents Scheduled Reports',
                    component: SafetyPage,
                    props: { route: '/admin/ScheduleReportsMaintV3?pId=6', dashboardNav: 'EHSScheduledReports' }
                  },
                  {
                    path: 'observations-scheduled-reports',
                    name: 'Observations Scheduled Reports',
                    component: SafetyPage,
                    props: { route: '/admin/ScheduleReportsMaintV3?pId=5', dashboardNav: 'EHSScheduledReports' }
                  },
                  {
                    path: 'jsa-scheduled-reports',
                    name: 'JSA Scheduled Reports',
                    component: SafetyPage,
                    props: { route: '/admin/ScheduleReportsMaintV3?pId=4', dashboardNav: 'EHSScheduledReports' }
                  },
                  {
                    path: 'safety-scan-scheduled-reports',
                    name: 'Safety Scan Scheduled Reports',
                    component: SafetyPage,
                    props: { route: '/admin/ScheduleReportsMaintV3?pId=7', dashboardNav: 'EHSScheduledReports' }
                  }
                ]
              }
            ]
          },
          {
            path: 'administration',
            component: EmptyRouterView,
            children: [
              {
                path: 'account-settings',
                name: 'Account Settings',
                component: SafetyPage,
                props: { route: '/admin/AccountDashboardMaintV3?pId=12' }
              },
              {
                path: 'billing-information',
                name: 'Billing Information',
                component: SafetyPage,
                props: { route: '/admin/AccountBillingInformationV2' }
              },
              {
                path: 'configure-user-roles',
                name: 'Configure User Roles-EHS',
                component: ConfigureUserRolesSwitch,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Configure User Roles')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'document-central',
                name: 'Document Central-EHS',
                component: DocumentCentral,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Document Central')
                  if (hasEntitledPermission(entitlement, guard) && store.getters.isShowDocumentCentral) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'safety-plan',
                name: 'Site Safety Plans-EHS',
                component: SafetyPlanHome,
                beforeEnter: async (to, from, next) => {
                  if (store.getters.isShowSafetyPlan) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'users',
                name: 'Users-EHS',
                component: UsersPage,
                beforeEnter: async (to, from, next) => {
                  if (store.getters.isShowUsersInAdmin) {
                    next()
                  } else {
                    router.push(from.path)
                  }
                }
              }
            ]
          },
          {
            path: 'integration',
            component: EmptyRouterView,
            children: [
              {
                path: 'procore-integration',
                name: 'Procore Integration',
                component: SafetyPage,
                props: { route: '/admin/ProcoreSettingsV3' }
              },
              {
                path: 'plangrid-integration',
                name: 'Plangrid Integration',
                component: SafetyPage,
                props: { route: '/admin/PlanGridSettingsV3' }
              }
            ]
          },
          {
            path: 'company-lists',
            component: EmptyRouterView,
            children: [
              {
                path: 'certifications',
                name: 'Certifications-EHS',
                component: ListsCertificationPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Certifications')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'contractors',
                name: 'Contractors-EHS',
                component: ListsContractorsPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Contractors')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'customers',
                name: 'Customers-EHS',
                component: ListsCustomersPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Customers')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'departments',
                name: 'Departments-EHS',
                component: ListsDepartmentsPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Departments')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'descriptions',
                name: 'Descriptions-EHS',
                component: ListsDescriptionsPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Descriptions')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'employees',
                name: 'Employees-EHS',
                component: ListsEmployeesPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Employees')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'employee-types',
                name: 'Employee Types-EHS',
                component: ListsEmployeeTypesPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Employee Types')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'locations',
                name: 'Locations-EHS',
                component: ListsLocationsPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Locations')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'manufacturers',
                name: 'Manufacturers-EHS',
                component: ListsManufacturersPage,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Manufacturers')
                  if (hasEntitledPermission(entitlement, guard)) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              }
            ]
          },
          {
            path: 'ehs-settings',
            component: EmptyRouterView,
            children: [
              {
                path: 'ehs-license-maintenance',
                component: EmptyRouterView,
                children: [
                  {
                    path: 'inspection-license-maintenance',
                    name: 'Inspection License Maintenance',
                    component: SafetyPage,
                    props: { route: '/admin/userMaintV3', dashboardNav: 'EHSLicenseMaintenance' }
                  },
                  {
                    path: 'training-license-maintenance',
                    name: 'Training License Maintenance',
                    component: SafetyPage,
                    props: { route: '/admin/TrainingUsersMaintV3', dashboardNav: 'EHSLicenseMaintenance' }
                  },
                  {
                    path: 'incident-license-maintenance',
                    name: 'Incident License Maintenance',
                    component: SafetyPage,
                    props: { route: '/admin/IncidentUsersMaintV3', dashboardNav: 'EHSLicenseMaintenance' }
                  },
                  {
                    path: 'observation-license-maintenance',
                    name: 'Observation License Maintenance',
                    component: SafetyPage,
                    props: { route: '/admin/ObsUsersMaintV3', dashboardNav: 'EHSLicenseMaintenance' }
                  },
                  {
                    path: 'jsa-license-maintenance',
                    name: 'JSA License Maintenance',
                    component: SafetyPage,
                    props: { route: '/admin/JSAUsersMaintV3', dashboardNav: 'EHSLicenseMaintenance' }
                  },
                  {
                    path: 'safety-scan-license-maintenance',
                    name: 'Safety Scan License Maintenance',
                    component: SafetyPage,
                    props: { route: '/admin/EqpUsersMaintV3', dashboardNav: 'EHSLicenseMaintenance' }
                  },
                  {
                    path: 'forms-license-maintenance',
                    name: 'Forms License Maintenance',
                    component: SafetyPage,
                    props: { route: '/admin/FormsUsersMaintV3', dashboardNav: 'EHSLicenseMaintenance' }
                  }
                ]
              },
              {
                path: 'company-maintenance',
                name: 'Company Maintenance',
                component: SafetyPage,
                props: { route: '/admin/CompanyMaintV3' }
              },
              {
                path: 'location-maintenance',
                component: EmptyRouterView,
                children: [
                  {
                    path: 'observation-location-maintenance',
                    name: 'Observation Location Maintenance',
                    component: SafetyPage,
                    props: { route: '/admin/ObsFacilityMaintV3', dashboardNav: 'EHSLocationMaintenance' }
                  },
                  {
                    path: 'incidents-location-maintenance',
                    name: 'Incidents Location Maintenance',
                    component: SafetyPage,
                    props: { route: '/admin/IncidentLocationMaintv3', dashboardNav: 'EHSLocationMaintenance' }
                  },
                  {
                    path: 'safety-scan-location-maintenance',
                    name: 'Safety Scan Location Maintenance',
                    component: SafetyPage,
                    props: { route: '/admin/EqpLocationMaintV3', dashboardNav: 'EHSLocationMaintenance' }
                  },
                  {
                    path: 'forms-location-maintenance',
                    name: 'Forms Location Maintenance',
                    component: SafetyPage,
                    props: { route: '/admin/FormsLocationMaintV3', dashboardNav: 'EHSLocationMaintenance' }
                  }
                ]
              },
              {
                path: 'responsible-party-maintenance',
                component: EmptyRouterView,
                children: [
                  {
                    path: 'inspection-pr-maintenance',
                    name: 'Inspection RP Maintenance',
                    component: SafetyPage,
                    props: { route: '/admin/SubcontractorMaintV3', dashboardNav: 'EHSResponsiblePartyMaintenance' }
                  },
                  {
                    path: 'incidents-pr-maintenance',
                    name: 'Incidents RP Maintenance',
                    component: SafetyPage,
                    props: { route: '/admin/IncidentRespPartyMaintV3', dashboardNav: 'EHSResponsiblePartyMaintenance' }
                  },
                  {
                    path: 'observation-pr-maintenance',
                    name: 'Observation RP Maintenance',
                    component: SafetyPage,
                    props: { route: '/admin/ObsRespPartyMaintV3', dashboardNav: 'EHSResponsiblePartyMaintenance' }
                  },
                  {
                    path: 'safety-scan-pr-maintenance',
                    name: 'Safety Scan RP Maintenance',
                    component: SafetyPage,
                    props: { route: '/admin/EqpRespPartyMaintV3', dashboardNav: 'EHSResponsiblePartyMaintenance' }
                  },
                  {
                    path: 'forms-pr-maintenance',
                    name: 'Forms RP Maintenance',
                    component: SafetyPage,
                    props: { route: '/admin/FormsRespPartyMaintV3', dashboardNav: 'EHSResponsiblePartyMaintenance' }
                  }
                ]
              },
              {
                path: 'data-export-api',
                component: EmptyRouterView,
                children: [
                  {
                    path: 'inspection-data-export-api',
                    name: 'Inspection Data Export / API',
                    component: SafetyPage,
                    props: { route: '/admin/DataExportMaintV3', dashboardNav: 'EHSDataExportAPI' }
                  },
                  {
                    path: 'training-data-export-api',
                    name: 'Training Data Export / API',
                    component: SafetyPage,
                    props: { route: '/admin/DataExportMaintV3', dashboardNav: 'EHSDataExportAPI' }
                  },
                  {
                    path: 'incident-data-export-api',
                    name: 'Incident Data Export / API',
                    component: SafetyPage,
                    props: { route: '/admin/DataExportMaintV3', dashboardNav: 'EHSDataExportAPI' }
                  },
                  {
                    path: 'observations-data-export-api',
                    name: 'Observations Data Export / API',
                    component: SafetyPage,
                    props: { route: '/admin/DataExportMaintV3', dashboardNav: 'EHSDataExportAPI' }
                  },
                  {
                    path: 'jsa-data-export-api',
                    name: 'JSA Data Export / API',
                    component: SafetyPage,
                    props: { route: '/admin/DataExportMaintV3', dashboardNav: 'EHSDataExportAPI' }
                  },
                  {
                    path: 'safety-scan-data-export-api',
                    name: 'Safety Scan Data Export / API',
                    component: SafetyPage,
                    props: { route: '/admin/DataExportMaintV3', dashboardNav: 'EHSDataExportAPI' }
                  },
                  {
                    path: 'forms-data-export-api',
                    name: 'Forms Data Export / API',
                    component: SafetyPage,
                    props: { route: '/admin/DataExportMaintV3', dashboardNav: 'EHSDataExportAPI' }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'equipment',
        component: EquipmentManagementRouterView,
        children: [
          {
            path: 'service-requests',
            name: 'Equipment Service Request',
            meta: {
              title: 'Equipment Service Request'
            },
            props: { isEquipmentService: true },
            component: ServiceRequestBrowser,
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowEquipmentNav) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'service-requests/edit',
            name: 'Edit Service Requests',
            meta: {
              title: 'Equipment Service Request'
            },
            props: { isEquipmentService: true },
            component: EditServiceRequest,
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowEquipmentNav) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'service-requests/add-items',
            name: 'Add Items To Service Request',
            meta: {
              title: 'Equipment Service Request'
            },
            props: { isEquipmentService: true },
            component: AddItemsToServiceRequest,
            beforeEnter: async (to, from, next) => {
              if (store.getters.isShowEquipmentNav) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'scheduling',
            name: 'Scheduling',
            component: EmptyRouterView,
            children: [
              {
                path: 'schedulingrequest',
                name: 'Equipment Scheduling Request',
                props: { isEquipmentService: true },
                component: SchedulingRequestNew,
                beforeEnter: async (to, from, next) => {
                  if (store.getters.isShowEquipmentNav) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'scheduling-approve-reject',
                name: 'Equipment Scheduling Approve Reject',
                component: SchedulingApproveReject,
                props: { isEquipmentService: true }
              },
              {
                path: 'scheduling-cart',
                name: 'Equipment Scheduling Cart',
                props: { isEquipmentService: true },
                component: SchedulingCart,
                beforeEnter: async (to, from, next) => {
                  if (store.getters.isShowEquipmentNav) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'checkout',
                name: 'Equipment Scheduling Checkout',
                props: { isEquipmentService: true },
                component: SchedulingCheckout,
                beforeEnter: async (to, from, next) => {
                  if (store.getters.isShowEquipmentNav) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              },
              {
                path: 'schedulingcalendar',
                name: 'Equipment Scheduling Calendar',
                props: { isEquipmentService: true },
                component: SchedulingCalendar,
                beforeEnter: async (to, from, next) => {
                  const { guard, entitlement } = getMatchedRoute('Scheduling Calendar')
                  if (hasEntitledPermission(entitlement, guard) &&
                  store.getters.isShowEquipmentNav) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              }
            ]
          },
          {
            path: 'usagelog',
            name: 'EquipmentUsageLog',
            meta: {
              title: 'Equipment Usage Log'
            },
            props: { isEquipmentService: true },
            component: EquipmentUsageLog,
            beforeEnter: async (to, from, next) => {
              const { guard, entitlement } = getMatchedRoute('Equipment Usage Log')
              if (hasEntitledPermission(entitlement, guard) &&
               store.getters.isShowEquipmentNav) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'work-orders',
            name: 'Equipment Work Order Browser',
            meta: {
              title: 'Equipment Work Order Browser'
            },
            props: { isEquipmentService: true },
            component: WorkOrders,
            beforeEnter: async (to, from, next) => {
              if (!isEHSOnly() && store.getters.isShowEquipmentNav) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'usagelog/equipment-add-item',
            name: 'Equipment Add Item',
            meta: {
              title: 'Tool Browser'
            },
            props: { isEquipmentService: true },
            component: EquipmentUsageAddItem,
            beforeEnter: async (to, from, next) => {
              if (!isEHSOnly() && store.getters.isShowEquipmentNav) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'work-orders/edit',
            name: 'Work Orders Edit',
            meta: {
              title: 'Work Order'
            },
            props: { isEquipmentService: true },
            component: WorkOrdersEdit,
            beforeEnter: async (to, from, next) => {
              if (!isEHSOnly() && store.getters.isShowEquipmentNav) {
                next()
              } else {
                try {
                  await router.push(from.path)
                } catch (error) { }
              }
            }
          },
          {
            path: 'scheduling',
            name: 'Scheduling',
            component: EmptyRouterView,
            children: [
              {
                path: 'schedulingbrowser',
                name: 'Equipment Scheduling Browser',
                props: { isEquipmentService: true },
                component: SchedulingBrowser,
                beforeEnter: async (to, from, next) => {
                  if (!isEHSOnly() && store.getters.isShowEquipmentNav) {
                    next()
                  } else {
                    try {
                      await router.push(from.path)
                    } catch (error) { }
                  }
                }
              }
            ]
          }
        ]
      },
      {
        path: 'wfm',
        component: workForcePage,
        beforeEnter: async (to, from, next) => {
          if (!isEHSOnly() && store.getters.isShowWFM) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        },
        props: { route: '/wfm' }
      }
    ]
  },
  {
    path: '/procore',
    component: EmptyRouterView,
    meta: {
      requiresAuth: true
    },
    beforeEnter: async (to, from, next) => {
      if (!isEHSOnly() && await checkProcoreIntegration()) {
        try {
          await router.push('/requisitions')
        } catch (error) { }
      } else {
        next()
      }
    },
    children: [
      {
        path: '',
        name: 'Procore Integration Warning',
        component: ProcoreWarning,
        beforeEnter: async (to, from, next) => {
          if (!isEHSOnly()) {
            next()
          } else {
            try {
              await router.push(from.path)
            } catch (error) { }
          }
        }
      }
    ]
  },
  {
    path: '/api-core-login',
    component: ApiCoreLogin
  },
  {
    path: '/move-to-cloud',
    component: MoveToCloud
  },
  {
    path: '*', component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!from.path.startsWith('/ehs') && !from.path.startsWith('/wfm') && to.path.startsWith('/ehs')) {
    store.commit('setLastTWRoute', from.path)
  } else if (from.path.startsWith('/ehs') && !to.path.startsWith('/ehs')) {
    store.commit('setLastEHSRoute', from.path)
  }
  if (to.path === '/ehs' || to.path === '/ehs/') {
    next({ path: '/ehs/dashboards' })
  }
  if (!from.name) {
    store.commit('setInitialLoading', true)
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    setProcoreIntegration(window.location.href)
    if (await isAuthenticated()) {
      store.commit('setInitialLoading', false)
      if (isJobsitePortal() && to.name === 'Dashboard' && !store.getters.showPlatformNavigation) {
        document.title = 'Job Site Dashboard | ToolWatch Cloud'
      } else if (to.meta.title) {
        document.title = setPageTitle(to.meta.title)
      } else {
        if (to.fullPath === '/ehs' && to.name === undefined) {
          document.title = setPageTitle('EHS')
        } else {
          if (to.fullPath === '/wfm' && to.name === undefined) {
            document.title = setPageTitle('WFM')
          } else {
            document.title = setPageTitle(to.name)
          }
        }
      }
      if (isFirstLogin() && isEHSOnly()) {
        store.commit('setFLogin', false)
        localStorage.setItem('jobSiteUser', 2)
        window.location.href = `${window.location.origin}/ehs/dashboards`
      }
      next()
    } else {
      const pathName = window.location.pathname
      const url = `${getLoginPath()}?redirect_url=${pathName}`
      try {
        store.commit('setInitialLoading', false)
        await router.push(url)
      } catch (error) {
        store.commit('setInitialLoading', false)
      }
    }
  } else {
    store.commit('setInitialLoading', false)
    next()
  }
})

const setPageTitle = (title) => {
  if (!store.getters.showPlatformNavigation) return `${title} | ${process.env.VUE_APP_TITLE}`

  if (title === 'Product Catalog') title = 'Catalog'
  else if (title === 'Retired Tools') title = 'Manage Retired Tools'
  else if (title === 'Pick Ticket Browser') title = 'Pick Tickets'
  else if (title === 'Count Ticket Browser') title = 'Count Tickets'
  else if (title === 'Template Browser') title = 'Templates'
  else if (title === 'Requistions') title = 'Requisitions Home'
  else if (title === 'Returns') title = 'Returns Home'
  else if (title === 'Return Home') title = 'Returns History'
  else if (title === 'Service Request Browser') title = 'Service Requests'
  else if (title === 'work Orders Browser') title = 'Work Orders'
  else if (title === 'Service Request Browser') title = 'Service Requests'
  else if (title === 'Purchase Order Browser') title = 'Purchase Orders'
  else if (title === 'Receiving Browser') title = 'Receiving Tickets'
  else if (title === 'Rate Sheet Browser') title = 'Rate Sheets'
  else if (title === 'Company Default Calender') title = 'Company Calendar'
  else if (title === 'Export Template Lists') title = 'Export Templates'
  else if (title === 'Data Export') title = 'Export Data'
  else if (title === 'Report Chooser') title = 'Reports'
  else if (title === 'Roles Management') title = 'Configure User Roles'
  else if (title === 'Integration Catalog') title = 'Catalog'
  else if (title === 'My Settings') title = 'Settings'
  else if (title === 'Data Merger') title = 'Data Merge Utility'
  else if (title === 'My Settings') title = 'Data Merge Utility'

  return `${title} | ${process.env.VUE_APP_TITLE}`
}

router.onError(error => {
  if (/loading chunk .* failed./i.test(error.message)) {
    store.commit('setRequiresReload', true)
  }
})

export default router
